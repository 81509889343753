import * as React from "react";
import Layout from "../../components/Navigation/layout";
// @ts-ignore
import * as style from './index.module.scss';
import * as yup from 'yup';
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";


const RegisterSuccess = () => {
    return (
        <Layout>
            <div className={style.logoutHolder}>
                <div className={style.background}>
                </div>
                <div className={"d-flex flex-column justify-content-center align-items-center"}>
                    <h2> Danke für deine Bewerbung! </h2>
                    <h3> Wir melden uns demnächst bei dir! </h3>
                </div>
            </div>
        </Layout>
    )
}

export default RegisterSuccess;
